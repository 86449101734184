import React, { Component } from "react"
import { Link } from "gatsby"
import { FaPodcast } from "react-icons/fa";
import { RiArticleLine } from "react-icons/ri";
import { BsCameraVideoFill } from "react-icons/bs";

import "./item.scss"

export default function MediaItem(props) {
  return (
    <Link to={`${props.slug}`} className="media-item">
      <span className="media-type">
        {
          {
            'podcast': <FaPodcast />,
            'video': <BsCameraVideoFill />,
            'article': <RiArticleLine />
          }[props.posttype]
        }
        {props.posttype}
      </span>
      <img
        src={
          props.image
            ? `/images/${props.posttype}s/${props.image}`
            : "https://cdn.vox-cdn.com/thumbor/COPWw44cjnssBrogx2JWjbKe6e8=/0x0:3600x2419/920x613/filters:focal(1512x922:2088x1498):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/66203616/usa_today_13845122.0.jpg"
        }
      />
      <div>
        <h2>{props.title}</h2>
        <p className="description">{props.description}</p>
      </div>
    </Link>
  )
}
